define(['app'], function(app) {

  var footerLinkColumns = function() {
    var component = {};

    var _config = {
      selectors: {
        toggle: '[data-toggle]',
        levelOneItems: '[data-level=one]',
        levelTwoItems: '[data-level=two]',
        levelTwoListItem: '.footerLinkColumns_levelTwoItem',
        iconCollapse: '[data-icon=collapse]',
        iconExpand: '[data-icon=expand]',
      },
      attributes: {
        toggle: 'data-toggle',
        level: 'data-level',
        key: 'data-key',
        link: 'data-link'
      },
      classes: {
        levelTwoExpanded: 'footerLinkColumns_levelTwo-expanded',
        iconHide: 'footerLinkColumns_icon-hide'
      },
      key: null
    };

    var _init = function(element) {
      component.element = element;
      component.toggles = component.element.querySelectorAll(component.config.selectors.toggle);
      component.levelOneItems = component.element.querySelectorAll(component.config.selectors.levelOneItems);
      component.levelTwoItems = component.element.querySelectorAll(component.config.selectors.levelTwoItems);
      component.levelTwoListItems = component.element.querySelectorAll(component.config.selectors.levelTwoListItems);
      component.collapseIcons = component.element.querySelectorAll(component.config.selectors.iconCollapse);
      component.expandIcons = component.element.querySelectorAll(component.config.selectors.iconExpand);

      component.addClickEventListeners();
    };

    var _addClickEventListeners = function() {
      for (var i = 0, l = component.toggles.length; i < l; i++) {
        component.toggles[i].addEventListener('click', component.toggleView);
      }
      for (var i = 0, l = component.levelTwoListItems.length; i < l; i++){
        component.levelTwoListItems[i].addEventListener('click', function(event) {
          event.stopPropagation();
        })
      }
    };

    var _toggleView = function(event) {
      event.stopPropagation();

      if (app.element.getAttribute(component.config.attributes.link, this) !== 'true') {
        component.config.key = app.element.getAttribute(component.config.attributes.key, this);

        if (app.element.getAttribute(component.config.attributes.toggle, this) === 'expand') {
          app.element.setAttribute(component.config.attributes.toggle, 'collapse', this);
          component.expandView();
        } else {
          app.element.setAttribute(component.config.attributes.toggle, 'expand', this);
          component.collapseView();
        }
      }
    };

    var _findItem = function(collection) {
      for (var i = 0, l = collection.length; i < l; i++) {
        if (app.element.getAttribute(component.config.attributes.key, collection[i]) === component.config.key) {
          return collection[i];
        }
      }
    };

    var _findLevelTwo = function() {
      return component.findItem(component.levelTwoItems);
    };

    var _findExpandIcon = function() {
      return component.findItem(component.expandIcons);
    };

    var _findCollapseIcon = function() {
      return component.findItem(component.collapseIcons);
    };

    var _expandView = function() {
      app.element.addClass(component.config.classes.levelTwoExpanded, component.findLevelTwo());
      app.element.removeClass(component.config.classes.iconHide, component.findCollapseIcon());
      app.element.addClass(component.config.classes.iconHide, component.findExpandIcon());
    };

    var _collapseView = function() {
      app.element.removeClass(component.config.classes.levelTwoExpanded, component.findLevelTwo());
      app.element.addClass(component.config.classes.iconHide, component.findCollapseIcon());
      app.element.removeClass(component.config.classes.iconHide, component.findExpandIcon());
    };

    component.config = _config;
    component.init = _init;
    component.addClickEventListeners = _addClickEventListeners;
    component.toggleView = _toggleView;
    component.findLevelTwo = _findLevelTwo;
    component.collapseView = _collapseView;
    component.expandView = _expandView;
    component.findExpandIcon = _findExpandIcon;
    component.findCollapseIcon = _findCollapseIcon;
    component.findItem = _findItem;

    return component;
  };

  return footerLinkColumns;
});

